<template>
  <div class="couponSection">
    <div class="head">
      <div class="title">
        <TicketIcon />
        คูปอง
      </div>
    </div>
    <div class="box" @click="openCouponDialog = true">
      <div v-if="!data.couponId" class="noCouponTxt">โค้ดส่วนลดคูปอง</div>
      <div v-if="data.couponId && !loading" class="useCouponTxt">
        {{ data.couponCode }}
      </div>
      <div v-if="loading" class="txtcenter">โหลดข้อมูล...</div>
      <div v-if="error" class="txtcenter">พบปัญหา กรุณาลองใหม่ภายหลัง</div>
    </div>
    <CouponDialog
      :open="openCouponDialog"
      @back="openCouponDialog = false"
      @useCoupon="useCoupon"
      :usedCouponId="usedCouponId"
    />
  </div>
</template>

<script>
import TicketIcon from "@/components/icon/TicketIcon";
import CouponDialog from "./CouponDialog";

export default {
  props: { usedCouponId: Number },
  components: {
    TicketIcon,
    CouponDialog,
  },
  data() {
    return {
      data: {},
      loading: false,
      error: false,
      openCouponDialog: false,
    };
  },
  methods: {
    useCoupon(e) {
      this.data = e;
      this.$emit("useCoupon", e);
    },
  },
  mounted() {
    // this.getAddresses();
  },
};
</script>

<style lang="scss" scoped>
.couponSection {
  margin-top: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      > img {
        margin-right: 4px;
      }
      svg {
        width: 20px;
        height: 20px;
        margin-top: 4px;
        margin-right: 6px;
        color: var(--primary-color);
      }
    }
  }
  .box {
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 13%);
    margin: 10px 0;
    padding: 10px;
    line-height: 25px;
    font-size: 14px;
  }
}

.noCouponTxt {
  color: darkgray;
}

.useCouponTxt {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 16px;
}
</style>