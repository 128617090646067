<template>
  <div class="backIcon" @click="clickBack()"></div>
</template>

<script>
const exceptRouteName = ["ChoosePermission"];
export default {
  props: { title: String, defaultPreRouteName: String, onlyEvent: Boolean },
  data() {
    return {};
  },
  methods: {
    clickBack() {
      this.$emit("click");
      if (this.onlyEvent) return;
      if (
        !window.$prevRoute?.name ||
        exceptRouteName.includes(window.$prevRoute.name)
      ) {
        if (this.defaultPreRouteName) {
          this.$router.push({ name: this.defaultPreRouteName });
          return;
        }
        this.$router.push("/");
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.backIcon {
  background-color: var(--primary-color);
  width: 25px;
  height: 25px;
  user-select: none;
  -webkit-mask: url(~@/assets/img/left-arrow.svg) no-repeat center / contain;
}
</style>