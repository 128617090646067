var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cartListPage"},[_c('NavbarBack',{attrs:{"title":'รถเข็น'}}),_c('DefaultDisplayStage',{attrs:{"fetching":_vm.fetching,"error":_vm.error,"nodata":_vm.cartItems.length == 0}}),(_vm.cartItems.length > 0 && !_vm.fetching)?_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"height":"30px"}}),_c('div',{staticClass:"list"},[_vm._l((_vm.cartItems),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"top",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push(`/products/${item.product.id}`)}}},[(
              item.product.productPhotos &&
              item.product.productPhotos.length > 0
            )?_c('img',{staticClass:"productPicture",attrs:{"src":item.product.productPhotos[0].url}}):_vm._e(),_c('div',{staticClass:"productDetail"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.product.name))]),_c('div',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();return _vm.remove(item.id)}}},[_c('img',{attrs:{"src":require("@/assets/img/trash.svg")}})]),_c('div',{staticClass:"priceWrap"},[_c('span',{staticClass:"price"},[(_vm.calStep(item.product))?_c('span',[_vm._v(_vm._s(_vm.calStep(item.product).min.toLocaleString())+" ~ "+_vm._s(_vm.calStep(item.product).max.toLocaleString()))]):_c('span',[_vm._v(_vm._s(item.product.salePrice.toLocaleString()))])]),_c('span',{staticClass:"unitPrice"},[_vm._v("บาท")]),(item.product.price !== item.product.salePrice)?_c('span',{staticClass:"fromPrice"},[_vm._v(_vm._s(item.product.price.toLocaleString())+" "),_c('span',{staticClass:"unitPrice"},[_vm._v("บาท")])]):_vm._e(),_c('span',{staticClass:"unit"},[_vm._v(" / "+_vm._s(item.product.unit))])])])]),(_vm.$isBuyer && item.product.product_status === 'วางขาย')?_c('div',{staticClass:"bottom"},[_c('div',[_c('div',{staticClass:"wrapQty"},[_c('span',[_vm._v("จำนวน")]),_c('span',{staticClass:"amountControl"},[_c('img',{attrs:{"src":require("@/assets/img/minus.svg")},on:{"click":function($event){$event.stopPropagation();return _vm.updateCartAmount({
                      cartId: item.id,
                      amount: item.amount - 1,
                      product: item.product,
                    })}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.amount),expression:"item.amount"}],attrs:{"type":"number","min":"0"},domProps:{"value":(item.amount)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.updateAmount({
                      cartId: item.id,
                      amount: $event.target.valueAsNumber,
                      product: item.product,
                    })},"input":function($event){if($event.target.composing)return;_vm.$set(item, "amount", $event.target.value)}}}),_c('img',{attrs:{"src":require("@/assets/img/add.svg")},on:{"click":function($event){$event.stopPropagation();return _vm.updateCartAmount({
                      cartId: item.id,
                      amount: item.amount + 1,
                      product: item.product,
                    })}}})])])]),_c('div',[_c('div',{staticClass:"wrapSamUnitPrice"},[_c('span',[_vm._v("ราคาต่อหน่วย ")]),_c('span',{staticClass:"totalPrice"},[_vm._v(" "+_vm._s(_vm.calUnitPrice(item.product, item.amount).toLocaleString())+" ")])]),_c('div',{staticClass:"summaryItem"},[_c('span',[_vm._v("ราคารวม ")]),_c('span',{staticClass:"totalPrice"},[_vm._v(" "+_vm._s(( _vm.calUnitPrice(item.product, item.amount) * item.amount ).toLocaleString())+" ")])])])]):_vm._e(),(item.product.product_status !== 'วางขาย')?_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"productErrorStatus"},[_vm._v(" "+_vm._s(item.product.product_status)+" ")])]):_vm._e()])}),_c('div',{staticStyle:{"height":"100px"}})],2)]):_vm._e(),(_vm.cartItems.length > 0)?_c('div',{staticClass:"bottomSection"},[_c('div',{staticClass:"summary"},[_c('span',[_vm._v("ราคารวมทั้งหมด")]),_c('div',[_c('span',{staticClass:"summaryPrice"},[_vm._v(_vm._s(_vm.summaryCart.toLocaleString()))]),_vm._v("บาท ")])]),_c('div',{class:[{ disable: !_vm.allowNext }, 'nextBtn btn'],on:{"click":function($event){return _vm.$router.push('/cart/confirm')}}},[_vm._v(" ดำเนินการต่อ ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }