<template>
  <div class="cartConfirm">
    <NavBar :showBack="true" title="สรุปคำสั่งซื้อ" />
    <div class="content" v-if="checkout && checkout.products.length > 0">
      <div style="height: 30px"></div>
      <div class="cartDetail">
        <div class="shopLine">
          <div><b>ร้านค้า : </b> {{ $sellerShop.name }}</div>
          <div></div>
        </div>
        <div>
          <span><b>ลูกค้า : </b></span>
          <span>{{ $myShop.name }}</span>
        </div>
        <div class="line"></div>
        <table>
          <thead>
            <tr>
              <th>รายการ</th>
              <th class="txt-r">จำนวน</th>
              <th class="txt-r">ราคา(บาท)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) of checkout.products" :key="index">
              <td>
                {{ index + 1 }}. {{ item.premium ? "[ของแถม]" : "" }}
                {{ item.product.name }}
              </td>
              <td class="txt-r">{{ item.qty.toLocaleString() }}</td>
              <td class="txt-r">
                {{ item.total.toLocaleString() }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="line"></div>
        <div class="summary">
          <span>ราคารวม</span>
          <div>
            <span class="summaryPrice">{{
              checkout.total.toLocaleString()
            }}</span>
          </div>
        </div>
        <div class="summary">
          <span>ส่วนลดโปรโมชั่น</span>
          <div>
            <span class="summaryPrice">{{
              checkout.promotion.toLocaleString()
            }}</span>
          </div>
        </div>
        <div class="summary">
          <span>ส่วนลดคูปอง</span>
          <div>
            <span class="summaryPrice">{{
              checkout.couponDiscount.toLocaleString()
            }}</span>
          </div>
        </div>
        <div class="summary">
          <span>ราคารวมทั้งหมด</span>
          <div>
            <span class="summaryPrice">{{
              checkout.net.toLocaleString()
            }}</span>
          </div>
        </div>
        <div class="summary">
          <span>คะแนนที่จะได้รับ</span>
          <div>
            <span class="summaryPrice">{{
              checkout.point.toLocaleString()
            }}</span>
          </div>
        </div>
      </div>
      <CouponSection
        ref="coupon"
        @useCoupon="useCoupon"
        :usedCouponId="checkout.couponId"
      />
      <ConfirmAddress ref="address" />
      <ConfirmPayment ref="payment" />
      <ConfirmNote ref="note" />
      <div style="height: 60px"></div>
    </div>
    <DefaultDisplayStage
      :nodata="checkout.products.length === 0"
      :fetching="loading"
    />
    <div
      v-if="checkout.products.length > 0"
      class="bottomSection"
      @click="confirmCart()"
    >
      <div class="nextBtn btn">ยืนยันสั่งซื้อ</div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
// import { call, get } from "vuex-pathify";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import ConfirmAddress from "./components/ConfirmAddress";
import ConfirmPayment from "./components/ConfirmPayment";
import ConfirmNote from "./components/ConfirmNote";
import CouponSection from "./components/CouponSection";

import { calUnitPrice } from "@/utils/product";

import { GET, POST } from "@/utils/http";

export default {
  name: "cartConfirm",
  components: {
    NavBar,
    DefaultDisplayStage,
    ConfirmAddress,
    ConfirmPayment,
    ConfirmNote,
    CouponSection,
  },
  data() {
    return {
      addressId: null,
      checkout: null,
      loading: false,
    };
  },
  watch: {
    async checkout() {
      if (
        this.checkout.products.some(
          (e) => e.premium === false && e.product.product_status !== "วางขาย"
        )
      ) {
        this.productStatusError();
      }
    },
  },
  methods: {
    calUnitPrice: calUnitPrice,
    // ...call("cart/*"),
    async productStatusError() {
      await window.$alert("พบสินค้ามีการเปลี่ยนสถานะ");
      this.$router.replace("/cart");
    },
    async getCheckout() {
      this.loading = true;
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/sellers/${this.$sellerShop.id}/checkout`
      );
      this.loading = false;

      if (status !== 200) {
        console.log({ body });
        await window.$alert(
          "เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลังหรือติดต่อเจ้าหน้าที่"
        );
        this.$router.back();
        return;
      }

      this.checkout = body;
    },
    async useCoupon(result) {
      this.checkout = result;
    },
    async confirmCart() {
      if (!this.$refs.address.data.id) {
        window.$alert("กรุณาเลือกใช้งานที่อยู่");
        return;
      }

      if (!this.$refs.payment.paymentSelectedId) {
        window.$alert("กรุณาเลือกช่องทางการชำระเงิน");
        return;
      }

      if (await window.$alert("ต้องการสั่งซื้อหรือไม่?", "confirm")) {
        this.postCheckout();
      }
    },
    async postCheckout() {
      window.loading(true);
      try {
        const payLoad = {
          shopAddressId: this.$refs.address.data.id,
          paymentTypeId: this.$refs.payment.paymentSelectedId,
          note: this.$refs.note.message,
          couponId: this.checkout.couponId || null,
        };

        window.loading(false);

        const { status, body } = await POST(
          `/api/shops/${this.$myShop.id}/sellers/${this.$sellerShop.id}/checkout`,
          payLoad
        );

        if (status === 422) {
          if (body.Length > 0) {
            this.productStatusError();
            return;
          }
        }

        if (status !== 200) {
          throw "checkout not ok";
        }

        this.$router.replace("/order");
      } catch (err) {
        await window.$alert("เกิดข้อขัดข้อง กรุณาทำรายการใหม่ภายหลัง");
      } finally {
        window.loading(false);
      }
    },
  },
  mounted() {
    if (this.$isBuyer) {
      this.getCheckout();
      // this.fetchListCart();
    }
  },
};
</script>
<style lang="scss" scoped>
.cartConfirm {
  background: #fff;
  min-height: 100%;
  position: relative;
  height: 100%;
  // overflow: hidden;
  height: 100%;
  overflow: auto;
  padding: 10px 0;
  font-size: 15px;

  > .content {
    padding: 15px;
  }
}

.cartDetail {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 13%);
  padding: 20px 10px 10px 10px;
}

.shopLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.txt-r {
  text-align: right;
}

table {
  line-height: 20px;
  text-align: left;
}
td,
th {
  padding: 1px 2px;
}

.line {
  border-bottom: 1px solid rgb(206, 206, 206);
  margin: 20px 0;
}

.name {
  margin-right: 26px;
  line-height: 22px;
}

.top {
  display: flex;
  flex: 1 1 auto;
}

.bottomSection {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 10px;
  background: #fff;
}

.summary {
  display: flex;
  justify-content: space-between;
  margin: 10px 4px;
  font-weight: bold;
  font-size: 15px;
}

.summaryPrice {
  color: var(--primary-color);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  max-width: 400px;
  margin: 0 auto;
}

.nextBtn {
  background: var(--primary-color);
  color: #fff;
  margin: 10px auto;
  max-width: 400px;
}
</style>
