<template>
  <div class="payment">
    <div class="head">
      <div class="title">
        <!-- <img src="@/assets/img/baht.svg" width="20px" alt="" /> -->
        <div class="mask-image"></div>
        การชำระเงิน
      </div>
      <div>
        <!-- <u>ดูช่องทางการชำระ</u> -->
      </div>
    </div>
    <div class="box">
      <div v-if="nodata" class="description">
        ร้านค้าเอเย่นต์ยังไม่ตั้งค่าประเภทชำระเงิน กรุณาแจ้งร้านค้าเพื่อตั้งค่า
      </div>
      <div v-for="(item, index) in data" :key="index">
        <input
          v-model="paymentSelectedId"
          :value="item.id"
          type="radio"
          :id="item.id"
          name="payment"
        />
        <label :for="item.id">{{ item.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { GET } from "@/utils/http";

export default {
  data() {
    return {
      data: {},
      loading: false,
      error: false,
      paymentSelectedId: 0,
    };
  },
  methods: {
    async getPaymentAllow() {
      this.loading = true;
      try {
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/partners/seller/${this.$sellerShop.id}/paymenttypes/enable?query=&order=`
        );

        if (status !== 200) {
          throw "payments not ok";
        }

        this.data = body;
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    nodata() {
      return this.data && this.data.length == 0;
    },
  },
  mounted() {
    this.getPaymentAllow();
  },
};
</script>

<style lang="scss" scoped>
.payment {
  margin-top: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      > img {
        margin-right: 4px;
      }
      .mask-image {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        mask-image: url("~@/assets/img/baht.svg");
      }
    }
  }
  .box {
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 13%);
    margin: 10px 0;
    padding: 10px;
    line-height: 25px;
    font-size: 14px;
  }
}
.description {
  font-style: italic;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary-color);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>