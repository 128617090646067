<template>
  <div class="nav">
    <div class="navInner">
      <div @click="clickBack()" class="backIcon"></div>
      <!-- <img @click="clickBack()" src="@/assets/img/left-arrow.svg" alt="" /> -->
      <span class="title">{{ title }}</span>
    </div>
  </div>
</template>

<script>
const exceptRouteName = ["ChoosePermission"];
export default {
  props: { title: String, defaultPreRouteName: String },
  data() {
    return {};
  },
  methods: {
    clickBack() {
      if (
        !window.$prevRoute?.name ||
        exceptRouteName.includes(window.$prevRoute.name)
      ) {
        if (this.defaultPreRouteName) {
          this.$router.push({ name: this.defaultPreRouteName });
          return;
        }
        this.$router.push("/");
      } else {
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  // background: white;
  width: 100%;
  //   height: 45px;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.137);

  .navInner {
    background: #ffffffc2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    // margin: 6px;
    border-radius: 7px;
    height: 40px;
    width: 100%;
    > img {
      width: 25px;
      position: absolute;
      left: 10px;
      user-select: none;
    }

    .backIcon {
      background-color: var(--primary-color);
      width: 25px;
      height: 25px;
      position: absolute;
      left: 10px;
      user-select: none;
      -webkit-mask: url(~@/assets/img/left-arrow.svg) no-repeat center / contain;
    }
  }

  .title {
    // padding-left: 8px;
    text-align: center;
    font-weight: bold;
  }
}
</style>