
<template>
  <div class="cartListPage">
    <NavbarBack :title="'รถเข็น'" />
    <DefaultDisplayStage
      :fetching="fetching"
      :error="error"
      :nodata="cartItems.length == 0"
    />
    <div class="content" v-if="cartItems.length > 0 && !fetching">
      <!-- <div>Coming soon</div> -->
      <div style="height: 30px"></div>
      <div class="list">
        <div class="item" v-for="(item, index) of cartItems" :key="index">
          <div
            class="top"
            @click.stop="$router.push(`/products/${item.product.id}`)"
          >
            <img
              class="productPicture"
              v-if="
                item.product.productPhotos &&
                item.product.productPhotos.length > 0
              "
              :src="item.product.productPhotos[0].url"
            />
            <div class="productDetail">
              <div class="name">{{ item.product.name }}</div>
              <div class="delete" @click.stop="remove(item.id)">
                <img src="@/assets/img/trash.svg" />
              </div>
              <div class="priceWrap">
                <span class="price">
                  <span v-if="calStep(item.product)"
                    >{{ calStep(item.product).min.toLocaleString() }} ~
                    {{ calStep(item.product).max.toLocaleString() }}</span
                  >
                  <span v-else>{{
                    item.product.salePrice.toLocaleString()
                  }}</span>
                </span>
                <span class="unitPrice">บาท</span>
                <span
                  v-if="item.product.price !== item.product.salePrice"
                  class="fromPrice"
                  >{{ item.product.price.toLocaleString() }}
                  <span class="unitPrice">บาท</span></span
                >
                <span class="unit"> / {{ item.product.unit }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="$isBuyer && item.product.product_status === 'วางขาย'"
            class="bottom"
          >
            <div>
              <div class="wrapQty">
                <span>จำนวน</span>
                <span class="amountControl">
                  <img
                    @click.stop="
                      updateCartAmount({
                        cartId: item.id,
                        amount: item.amount - 1,
                        product: item.product,
                      })
                    "
                    src="@/assets/img/minus.svg"
                  />
                  <input
                    type="number"
                    min="0"
                    v-model="item.amount"
                    @click.stop
                    @change="
                      updateAmount({
                        cartId: item.id,
                        amount: $event.target.valueAsNumber,
                        product: item.product,
                      })
                    "
                  />
                  <img
                    @click.stop="
                      updateCartAmount({
                        cartId: item.id,
                        amount: item.amount + 1,
                        product: item.product,
                      })
                    "
                    src="@/assets/img/add.svg"
                  />
                </span>
              </div>
            </div>
            <div>
              <div class="wrapSamUnitPrice">
                <span>ราคาต่อหน่วย </span>
                <span class="totalPrice">
                  {{ calUnitPrice(item.product, item.amount).toLocaleString() }}
                </span>
              </div>
              <div class="summaryItem">
                <span>ราคารวม </span>
                <span class="totalPrice">
                  {{
                    (
                      calUnitPrice(item.product, item.amount) * item.amount
                    ).toLocaleString()
                  }}
                </span>
              </div>
            </div>
          </div>
          <div v-if="item.product.product_status !== 'วางขาย'" class="bottom">
            <div class="productErrorStatus">
              {{ item.product.product_status }}
            </div>
          </div>
        </div>
        <div style="height: 100px"></div>
      </div>
    </div>
    <div v-if="cartItems.length > 0" class="bottomSection">
      <div class="summary">
        <span>ราคารวมทั้งหมด</span>
        <div>
          <span class="summaryPrice">{{ summaryCart.toLocaleString() }}</span
          >บาท
        </div>
      </div>
      <div
        :class="[{ disable: !allowNext }, 'nextBtn btn']"
        @click="$router.push('/cart/confirm')"
      >
        ดำเนินการต่อ
      </div>
    </div>
  </div>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import { get, call } from "vuex-pathify";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import { calStep, calUnitPrice } from "@/utils/product";

export default {
  name: "cartList",
  components: { NavbarBack, DefaultDisplayStage },
  data() {
    return {};
  },
  computed: {
    ...get("cart/*"),
    allowNext() {
      return !this.cartItems.some((e) => e.product.product_status !== "วางขาย");
    },
  },
  methods: {
    calStep: calStep,
    calUnitPrice: calUnitPrice,
    ...call("cart/*"),
    async remove(cartId) {
      if (!(await window.$alert("ต้องการลบหรือไม่?", "confirm"))) {
        return;
      }
      this.removeCart(cartId);
    },
    updateAmount(obj) {
      if (obj.amount < 1) {
        obj.amount = 1;
      }
      this.updateCartAmount({
        cartId: obj.cartId,
        amount: obj.amount,
        product: obj.product,
      });
    },
  },
  created() {
    if (this.$isBuyer) {
      this.fetchListCart();
    }
  },
};
</script>
<style lang="scss" scoped>
.cartListPage {
  background: #fff;
  min-height: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.title {
  margin: 20px 0 0 20px;
  font-size: 24px;
  padding: 10px;
}

.content {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  height: 100vh;
  overflow: auto;
  font-size: 15px;
}

.list {
  width: 100%;
  padding: 10px;
}

.item {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 0px 1px rgb(0 0 0 / 13%);
  margin: 14px 0;
  overflow: hidden;
}

.name {
  margin-right: 26px;
  line-height: 22px;
}

.top {
  display: flex;
  flex: 1 1 auto;
}

.productPicture {
  display: block;
  margin: 0 auto;
  width: 30%;
  height: 30%;
  max-width: 140px;
  max-height: 140px;
  aspect-ratio: 1/1;
}

.productDetail {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  // display: inline;
  // vertical-align: top;
  padding: 10px;

  .delete {
    position: absolute;
    width: 30px;
    right: 10px;
    top: 10px;
    border-radius: 100%;
    padding: 5px;
    img {
      width: 100%;
      aspect-ratio: 1/1;
    }
    &:active {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.priceWrap {
  margin-top: 10px;
}

.price {
  padding-right: 4px;
  color: var(--primary-color);
}

.fromPrice {
  margin-left: 6px;
  color: #989898;
  text-decoration: line-through;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 7px 7px 7px 7px;
  margin-bottom: 4px;
  .wrapQty {
    font-size: 14px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  .summaryItem,
  .wrapSamUnitPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wrapSamUnitPrice {
    margin-bottom: 4px;
    font-size: 14px;
  }

  input {
    border-radius: 8px;
    border: 1px solid rgb(192, 192, 192);
    padding: 2px;
    width: 70px;
    font-size: 13px;
    text-align: center;
    margin: 0 5px;
  }
}

.totalPrice {
  margin-left: 5px;
  color: var(--primary-color);
}

.amountControl {
  margin: 0 7px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  input {
    padding: 2px;
    font-size: 14px;
  }
  img {
    width: 20px;
  }
}

.bottomSection {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.summary {
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
}

.summaryPrice {
  margin: 0 10px;
  color: var(--primary-color);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  max-width: 400px;
  margin: 0 auto;
}

.nextBtn {
  background: var(--primary-color);
  color: #fff;
  margin: 10px auto;
  max-width: 400px;
  &.disable {
    pointer-events: none;
    background: #858585b0;
    color: #d3d3d3;
  }
}

.productErrorStatus {
  color: #ec4646;
  text-align: right;
  width: 100%;
}
</style>
