<template>
  <div class="navbar">
    <div class="innerNav">
      <div style="flex: 1">
        <BackButton
          :onlyEvent="backOnlyEvent"
          @click="$emit('back')"
          v-if="showBack"
        />
      </div>
      <div class="title">{{ title }}</div>
      <div style="flex: 1">
        <slot name="left-section" />
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "@/components/BackButton";
export default {
  props: {
    showBack: Boolean,
    title: String,
    backOnlyEvent: Boolean,
  },
  components: {
    BackButton,
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.navbar {
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  height: 45px;
  width: 100%;
  > .innerNav {
    display: flex;
    color: #242424;
    justify-content: space-between;
    font-size: 14px;
    height: 100%;
    // line-height: 20px;
    padding: 0 14px;
    background: #ffffffc2;
    align-items: center;
    // margin: 0 auto;
  }
}

.title {
  font-size: 16px;
  color: #606060;
  font-weight: bold;
}
</style>