<template>
  <div class="commentSection">
    <div class="head">
      <div class="title">
        <!-- <img src="@/assets/img/commentary.svg" width="20px" alt="" /> -->
        <div class="mask-image"></div>
        หมายเหตุ
      </div>
      <div></div>
    </div>
    <textarea v-model="message" id="noteConfirm" rows="4"></textarea>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      error: false,
      message: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.commentSection {
  margin-top: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      > img {
        margin-right: 4px;
      }
      .mask-image {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        mask-image: url("~@/assets/img/commentary.svg");
      }
    }
  }
  #noteConfirm {
    all: inherit;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 13%);
    margin: 10px 0;
    padding: 10px;
    line-height: 25px;
    font-size: 14px;
  }
}
</style>