<template>
  <div class="location">
    <div class="head">
      <div class="title">
        <!-- <img src="@/assets/img/location.svg" width="20px" alt="" /> -->
        <div class="mask-image"></div>
        ที่อยู่จัดส่ง
      </div>
      <div>
        <u
          @click="
            $router.push({
              name: 'AddressSetting',
              params: { isCheckout: true },
            })
          "
          >เลือกที่อยู่จัดส่ง</u
        >
      </div>
    </div>
    <div class="box">
      <div v-if="!data.id" class="txtcenter">กรุณาเลือกใช้งานที่อยู่</div>
      <div v-if="data.id && !loading">
        {{ data.contact_person }}, {{ data.tel }} {{ data.district.name }}
        {{ data.district.province }} {{ data.postcode }}
      </div>
      <div v-if="loading" class="txtcenter">โหลดข้อมูล...</div>
      <div v-if="error" class="txtcenter">พบปัญหา กรุณาลองใหม่ภายหลัง</div>
      <!-- ชื่อผู้ติดต่อ , เบอร์โทรศัพท์ <br />
      เลขที่ ซอย ถนน แขวง เขต จังหวัด รหัสไปรษณีย์ -->
    </div>
  </div>
</template>

<script>
import { GET } from "@/utils/http";

export default {
  data() {
    return {
      data: {},
      loading: false,
      error: false,
    };
  },
  methods: {
    async getAddresses() {
      this.loading = true;
      try {
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/addresses`
        );

        if (status !== 200) {
          throw "address not ok";
        }

        this.data = body.data.find((e) => e.is_default === true) || {};
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAddresses();
  },
};
</script>

<style lang="scss" scoped>
.location {
  margin-top: 30px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      > img {
        margin-right: 4px;
      }
      .mask-image {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        mask-image: url("~@/assets/img/location.svg");
      }
    }
  }
  .box {
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 13%);
    margin: 10px 0;
    padding: 10px;
    line-height: 25px;
    font-size: 14px;
  }
}
</style>