<template>
  <div v-if="open" class="couponDialog">
    <NavBar
      :backOnlyEvent="true"
      @back="$emit('back')"
      :showBack="true"
      title="เลือกคูปอง"
      style="z-index: 10; transform: translate3d(0, 0, 0)"
    >
    </NavBar>

    <div class="title">คูปองของฉัน</div>
    <div class="list">
      <div class="box" v-for="(c, i) of coupons" :key="i">
        <div>
          <div>{{ c.privilege.title }}</div>
          <div>
            ใช้ก่อน {{ c.expiresAt !== "" ? formatDate(c.expiresAt) : "-" }}
          </div>
        </div>
        <div>
          <div
            v-if="usedCouponId != c.id"
            class="btn useBtn"
            @click="getCheckoutWithCoupon(c.id)"
          >
            ใช้
          </div>
          <div v-else class="btn usedBtn" @click="getCheckoutWithCoupon(null)">
            ยกเลิก
          </div>
        </div>
      </div>
    </div>
    <DefaultDisplayStage
      :fetching="loading"
      :error="error"
      :nodata="coupons.length == 0"
    />
  </div>
</template>

<script>
import { GET, POST } from "@/utils/http";
import NavBar from "@/components/NavBar";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";

export default {
  props: { open: Boolean, usedCouponId: Number },
  components: {
    NavBar,
    DefaultDisplayStage,
  },
  data() {
    return {
      coupons: [],
      loading: false,
      error: false,
    };
  },
  watch: {
    open(v) {
      if (v) {
        this.coupons = [];
        this.loading = false;
        this.error = false;
        this.getData();
      }
    },
  },
  methods: {
    formatDate(date) {
      try {
        return new Intl.DateTimeFormat("th", {
          year: "2-digit",
          month: "2-digit",
          day: "numeric",
        }).format(new Date(date));
      } catch (error) {
        return "";
      }
    },
    async getData() {
      this.loading = true;
      try {
        const { status, body } = await GET(`/api/coupons/my_coupon`);

        if (status !== 200) {
          throw "my_coupon not ok";
        }

        this.coupons = body;
      } catch (err) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async getCheckoutWithCoupon(couponId) {
      window.loading(true);
      const { status, body } = await POST(
        `/api/shops/${this.$myShop.id}/useCoupon`,
        { couponId: parseInt(couponId) }
      );
      window.loading(false);

      if (status !== 200) {
        await window.$alert("ขออภัย ใช้คูปองไม่ได้ในขณะนี้");
        return;
      }

      this.$emit("useCoupon", body);
      this.$emit("back");
    },
  },
  mounted() {
    // this.getData();
  },
};
</script>

<style lang="scss" scoped>
.couponDialog {
  padding: 60px 15px;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100%;
  width: 100%;
  z-index: 4;
  .box {
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 13%);
    margin: 10px 0;
    padding: 6px 10px;
    line-height: 25px;
    font-size: 14px;
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    color: rgb(90, 90, 90);
    > div:nth-child(1) {
      flex: 1;
      overflow-wrap: anywhere;
      min-width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div:nth-child(1) {
        font-weight: bold;
      }
      > div:nth-child(2) {
        font-size: 13px;
      }
    }
    > div:nth-child(2) {
      min-width: 60px;
    }
  }

  .btn {
    border-radius: 7px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    font-size: 13px;
    width: 60px;
    padding: 2px 5px;
  }

  .useBtn {
    color: #fff;
    background: var(--primary-color);
  }

  .usedBtn {
    color: #fff;
    background: dimgray;
  }
}
</style>